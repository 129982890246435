import { AccountSlice, createAccountSlice } from './slices/account.slice';
import { createFormSlice, FormSlice } from './slices/form.slice';
import { createUploadSlice, UploadSlice } from './slices/upload.slice';
import { create } from 'zustand';

export type BoundStore = FormSlice & UploadSlice & AccountSlice;

export const craeteBoundStore = () =>
  create<BoundStore>()((...a) => ({
    ...createFormSlice(...a),
    ...createUploadSlice(...a),
    ...createAccountSlice(...a),
  }));
